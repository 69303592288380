<template>
  <div>
    <div v-show="exactRoute">
      <v-card>
        <v-card-title>
          Karty objektu
          <v-spacer></v-spacer>
          <v-switch
            v-model="dateFilterType"
            :label="dateFilterType ? 'Editováno' : 'Vytvořeno'"
          ></v-switch>
          <vc-date-picker
            v-model="dateFilter"
            :columns="2"
            :popover="{ visibility: 'focus' }"
            color="blue"
            is-range>
            <template v-slot="{ dateFilter: inputValue, inputEvents }">
              <div class="d-flex justify-center items-center">
                <v-text-field
                  v-on="inputEvents.start"
                  :value="getLocalizedDate(dateFilter.start)"
                  class="rounded-0 border-right-none"
                  dense
                  hide-details
                  outlined
                  placeholder="Od"
                  readonly
                  style="max-width: 120px"
                />
                <v-text-field
                  v-on="inputEvents.end"
                  :value="getLocalizedDate(dateFilter.end)"
                  class="rounded-0 border-left-none"
                  dense
                  hide-details
                  outlined
                  placeholder="Do"
                  readonly
                  style="max-width: 120px"
                />
              </div>
            </template>
          </vc-date-picker>
          <v-select
            v-model="approvalProcessStateFilter"
            :items="approvalProcessStates"
            class="rounded-0"
            deletable-chips
            dense
            hide-details
            hide-selected
            label="Filtr stavu"
            multiple
            outlined
            small-chips
          >
          </v-select>
          <v-form v-model="searchValid" class="d-flex">
            <v-text-field
              ref="searchInput"
              v-model.trim="search"
              :rules="[validation.required]"
              append-icon="mdi-magnify"
              class="rounded-0"
              dense
              hide-details
              label="Vyhledat"
              outlined
              single-line
            ></v-text-field>
            <v-select
              ref="searchTypeInput"
              v-model="searchType"
              :items="searchTypes"
              :rules="[validation.required]"
              class="rounded-0"
              dense
              hide-details
              label="Vyhledat podle"
              outlined
              single-line
            >
            </v-select>
            <v-btn
              :disabled="!searchValid || runningRequest"
              class="rounded-0"
              color="primary"
              @click="filterBySearch"
            >Vyhledat
            </v-btn>
            <v-btn
              :disabled="!searchActive || runningRequest"
              class="rounded-0"
              color="error"
              @click="resetSearch()"
            >
              Zrušit vyhledávání
            </v-btn>
          </v-form>
        </v-card-title>
        <v-data-table
          :footer-props="{'items-per-page-options': [10, 20, 50] }"
          :headers="headers"
          :items="properties"
          :loading="runningRequest"
          :options.sync="paginationOptions"
          :server-items-length="totalProperties"
          class="elevation-1"
        >
          <template v-slot:item.name="{ item }">
            {{item.name}} <span v-if="item.parentPropertyId">- editace karty {{item.parentPropertyId}} </span>
          </template>
          <template v-slot:item.approvalProcessState="{ item }">
            <v-chip
              :color="getColor(item.approvalProcessState)"
              dark
            >
              {{ getApprovalProcessStateText(item.approvalProcessState) }}
            </v-chip>
          </template>
          <template v-slot:item.createdAt="{ item }">
            {{ getLocalizedDate(item.createdAt) }}
          </template>
          <template v-slot:item.updatedAt="{ item }">
            <span v-if="item.updatedAt">
              {{ item.updatedAt | localizedDate }}
            </span>
            <span v-else>-</span>
          </template>
          <template v-slot:item.owner="{ item }">
            <a :href="`/admin/hosts/${item.owner.id}`" class="table-link">
              {{ item.owner.firstName }} {{ item.owner.lastName }}
            </a>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="item.approvalProcessState !== 3 /* pouze pokud karta neni schvalena */"
              class="mr-2"
              small
              @click="changeApprovalProcessState(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              class="mr-2"
              small
              @click="deleteProperty(item)"
            >
              mdi-delete
            </v-icon>
            <v-icon
              class="mr-2"
              small
              @click="redirectToDetail(item)"
            >
              mdi-information-variant
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
      <v-dialog
        v-model="editDialog"
        width="450">
        <ApprovalProcessStateForm
          v-if="editDialog"
          :property="editedProperty"
          @edit-success="showApprovalProcessStatesChangeSuccess"
          @edit-failure="showApprovalProcessStatesChangeFailure"
        />
      </v-dialog>
    </div>
    <v-dialog v-model="deleteDialog">
      <DeleteResource
        v-if="deleteDialog"
        :resource="propertyToDelete"
        resource-type="admin/properties"
        @delete-success="showDeleteSuccess(); getProperties()"
        @delete-failure="showDeleteFailure"
      />
    </v-dialog>
    <router-view ref="childView"></router-view>
  </div>
</template>

<script>
import ApprovalProcessStateForm from '@/components/demands/ApprovalProcessStateForm'
import { mapActions } from 'vuex'
import propertiesOverviewHelpers from '../../mixins/local/propertiesOverviewHelpers'
import DeleteResource from '@/components/DeleteResource'
import archiveHelpers from '@/mixins/local/archiveHelpers'

export default {
  name: 'Cards',
  components: {
    DeleteResource,
    ApprovalProcessStateForm
  },
  mixins: [propertiesOverviewHelpers, archiveHelpers],
  data () {
    return {
      search: '',
      searchValid: true,
      searchActive: false,
      searchType: null,
      searchTypes: [
        {
          text: 'Název',
          value: 'name'
        },
        {
          text: 'Hostitel',
          value: 'host'
        }
      ],
      runningRequest: false,
      approvalProcessStateFilter: [],
      dateFilterType: true, // true - edited, false - created
      dateFilter: {
        start: null,
        end: null
      },
      headers: [
        {
          text: 'Název',
          align: 'start',
          value: 'name'
        },
        {
          text: 'ID',
          value: 'id',
          sortable: false
        },
        {
          text: 'Stav',
          value: 'approvalProcessState'
        },
        {
          text: 'Vytvořeno',
          value: 'createdAt'
        },
        {
          text: 'Poslední editace',
          value: 'updatedAt'
        },
        {
          text: 'Hostitel',
          value: 'owner'
        },
        {
          text: 'Hodnocení',
          value: 'rating'
        },
        {
          text: 'Akce',
          value: 'actions',
          sortable: false
        }
      ],
      properties: [],
      propertyToDelete: {},
      totalProperties: 0,
      exactRoute: true
    }
  },
  watch: {
    approvalProcessStateFilter: {
      deep: true,
      handler () {
        this.loadProperties()
      }
    },
    dateFilter: {
      deep: true,
      handler () {
        this.loadProperties()
      }
    },
    dateFilterType: {
      handler () {
        this.loadProperties()
      }
    },
    '$route.params': {
      deep: true,
      handler (newVal, oldVal) {
        // reload pri prechodu z detailu na prehled
        if (oldVal.id && !newVal.id) {
          this.getProperties()
        }
      }
    }
  },
  methods: {
    loadProperties () {
    },
    getProperties () {
      this.runningRequest = true
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage
      } = this.paginationOptions

      let dateFilterTypeTmp = null
      if (this.dateFilter.start) {
        dateFilterTypeTmp = this.dateFilterType ? 'edit' : 'create'
      }
      this.$http.get('/admin/properties', {
        params: {
          sortBy: sortBy.length ? sortBy[0] : null,
          sortDesc: sortDesc.length ? sortDesc[0] : null,
          search: this.search || null,
          // eslint-disable-next-line no-extra-boolean-cast
          searchType: !!this.searchType ? this.searchType : null,
          dateFilterType: dateFilterTypeTmp,
          from: this.dateFilter.start ? this.dateFilter.start.toISOString().slice(0, 10) : null,
          to: this.dateFilter.end ? this.dateFilter.end.toISOString().slice(0, 10) : null,
          approvalProcessStates: this.approvalProcessStateFilter,
          page,
          itemsPerPage
        }
      })
        .then(res => {
          this.properties = res.data.properties
          this.totalProperties = +res.data.total
        })
        .finally(() => {
          this.runningRequest = false
        })
    },
    filterBySearch () {
      this.searchActive = true
      this.getProperties()
    },
    resetSearch () {
      this.$refs.searchInput.reset()
      this.$refs.searchTypeInput.reset()
      this.searchActive = false
      this.getProperties()
    },
    deleteProperty (property) {
      this.deleteDialog = true
      this.propertyToDelete = property
    },
    redirectToDetail (property) {
      this.$router.push(`/admin/properties/${property.id}`)
    },
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    })
  },
  mounted () {
    this.getProperties()
    this.loadProperties = this.debounce(this.getProperties, 2000)
    this.exactRoute = typeof this.$refs.childView === 'undefined'
  },
  updated () {
    this.exactRoute = typeof this.$refs.childView === 'undefined'
  }
}
</script>

<style scoped>

</style>
